import { Injectable } from '@angular/core';
import {HttpClient , HttpHeaders } from '@angular/common/http';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  getDocument(filename: any) {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient,public Location:Location) { }
  sendserver(postData:any[]){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    // return this.http.post("http://localhost/helpdesk_apis/api-helpdesk/v1.0/index.php",postData,httpOptions);
    return this.http.post("https://ticketing.ringq.io/api/v1.0/index.php",postData,httpOptions);
  }
  GoBack(){
    return this.Location.back();
   }
}
