import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {Location} from '@angular/common';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ServerService } from '../services/server.service';
// import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DatePipe } from '@angular/common';
import { MenuComponent } from '../menu/menu.component';


declare var tinymce: any;

// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

// import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';

declare var $: any;
declare var iziToast: any;
import Swal from 'sweetalert2'
import { modelChanged } from '@syncfusion/ej2-angular-richtexteditor';

export interface Collobrator {
  email_name: string;
}
export interface EmailAddress {
  email_to: string;
}
@Component({
  selector: 'app-ticket-view-thread',
  templateUrl: './ticket-view-thread.component.html',
  styleUrls: ['./ticket-view-thread.component.css']
})
export class TicketViewThreadComponent implements OnInit {
  addCustomerDetails:FormGroup;
  enquiryDetailsData:FormGroup;
  Remainder:FormGroup;
  //CC Collabrator
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  collobrators: Collobrator[] = [];
  EmailToAddress: EmailAddress[] = [];
  show_email_errors=false;
  email_error_msg;
  addSenderThread = [];//Send ID for forward thread
  emailFormControl:FormControl;
  disabled:boolean = true;
  contract_detial=[];
  customer_name;
  customer_id;
  signature_strategy;
  switch_signature;
  agent_options_dept;
  suggestEmailList:any;
  config: any;
  ticket_type: any;
  enquiryTypeList:any;
  closedTypeList:any;
  closedNotification:any = 1;
  commentContent:any;
  commentcloseContent:any;
  ticket_agent_priority: any;
  ticket_agent_country: any;
  agent_options_list:any;
  ticket_agent_requestType: any;
  requestType: any;
  country: any;
  priority_name;
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const input = event.input;
    //     const input = event.input;
    // const value = event.value;
    // Add our fruit


    if (value) {
      this.collobrators.push({ email_name: value });
      event.value = "";
    }

    if (input) {
      input.value = '';
    }

    //  event.chipInput!.clear();
  }

  remove(collobrator: Collobrator): void {
    const index = this.collobrators.indexOf(collobrator);

    if (index >= 0) {
      this.collobrators.splice(index, 1);
    }
  }

  //CC Collabrator End
  addTo(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const input = event.input;

    // Add our fruit
    if (value) {
      this.EmailToAddress.push({ email_to: value });
      console.log(this.EmailToAddress)
    }

    if (input) {
      input.value = '';
    }

    // event.chipInput!.clear();
  }

  removeTo(EmailAddress: EmailAddress): void {
    const index = this.EmailToAddress.indexOf(EmailAddress);

    if (index >= 0) {
      this.EmailToAddress.splice(index, 1);
    }
  }

  //CC Collabrator End











  //   public tools: object = {
  //     items: ['Undo', 'Redo', '|',
  //         'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
  //         'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
  //         'SubScript', 'SuperScript', '|',
  //         'LowerCase', 'UpperCase', '|',
  //         'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
  //         'Indent', 'Outdent', '|', 'CreateLink',
  //         'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen']
  // };
  // public Editor = ClassicEditor;
  ticket_t;
  admin_id;
  user_id;
  access_token;
  ticket_replies;

  ticket_replies_all;

  tick_subject;
  ticket_created_by;
  tick_from;
  ticket_status_sel;
  ticket_status;
  first_letter;
  department;
  tick_time;
  customer_role;
  ticket_to;
  rep_to;
  status;
  mainCont;
  closed = false;
  first_res_time;
  closed_time;
  closed_by;
  dept_list;
  agent_options;
  ticket_agent;
  ticket_message_id;
  profile_pic;
  pageLimit = 5;
  offset_count = 0;
  showmore_button = false;
  total_offet;
  tick_id_ency;
  ext;
  singnatures;
  signature_title;
  signature_content;
  user_type;
  reply_from_arr = new Array();
  ticket_to_arr = new Array();
  ticket_cc;
  own_mail;
  markspamshow = false;
  unmarkspamshow = false;
  replied_from;
  user_name;
  selectedDepart = '';
  selectedEnquiryType = '';
  selectedDepart_perm;
  selectedAgentID;
  Reopen_closed = false;
  ticket_delete_status=false;
  admin_permission;
  has_robin;
  Alias_emails;
  customer_details;
  customer_details_all;
  start_list;
  end_list;
  showdatalist=false;
  listsuggestion;
  CanClose;//Can close only if ticket was claimed
  thread_order;
  upFile=[];
  upFileOBJ=[];
  scroll_mybutton;
  shared_agents;
  ticket_created_date;
  page_type;
  revisit;
  outcome_list;
  outcomeSelectValue;
  CloseRevisit;
  revisit_show = false;
  revisitShowClose = false;
  showAlertData = false;
  show_convert = false;
  priorityList : any;
  countryList : any;
  priority;
  supportRequestTypeList : any;
  enquirySuggest : any;
  selectedCountry : any;
  prorityFlagsSelect = 'Select Priority';
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  constructor(private DatePipe : DatePipe,private serverService: ServerService, private router: Router, private route: ActivatedRoute, private _location: Location,private sanitizer: DomSanitizer, private tinymce: EditorModule) {

    this.ticket_t = this.route.snapshot.queryParamMap.get('ticket_id');
    this.page_type = this.route.snapshot.queryParamMap.get('page_type');



    this.serverService.EmailNotify.subscribe( (val:any) => 
    {
    
     var dpContent = JSON.parse(val);
     console.log(dpContent);   
     
     if(dpContent.pagefor == "email_ticketing"){
      // this.ticket_t=dpContent.id;
      // this.getTicketDetails_noti(dpContent.id);
      // alert('called')
     }
    });



  }JSON:'' 




  ngOnInit() {
    
    this.route.queryParams.subscribe(val => {

    this.ticket_t = val.ticket_id;

       //  ============ pagination ============

       this.config = {
        itemsPerPage: 30,
        currentPage: 1,
      };
  
       //  ============ pagination ENDS ============


       this.addCustomerDetails = new FormGroup({
        'customer_name_email' : new FormControl(null,Validators.required),
        'CustomerEmailId' : new FormControl(null, Validators.email),
        'customer_phone' : new FormControl(null),
        'customer_country' : new FormControl(null),

       });

       this.Remainder = new FormGroup({
        'remaindarNotes' : new FormControl(null,Validators.required),
        'remainderDate' : new FormControl(null, Validators.required),

       });
       this.enquiryDetailsData = new FormGroup({
        // 'enquiryName' : new FormControl(null,Validators.required),
        // 'enquiryEmailId' : new FormControl(null, Validators.email),
        // 'enquiryPno' : new FormControl(null,Validators.required),
        'enquiryCmpy' : new FormControl(null,Validators.required),
        // 'enquiryAddr' : new FormControl(null, Validators.required),
        'enquiryCotry' : new FormControl(null, Validators.required),
        'enquiryComments' : new FormControl(null,Validators.required),
        // 'enquiryFrom' : new FormControl(null, Validators.required),
        // 'enquiryIpadd' : new FormControl(null),

       });



    var self=this;     
    $(document).ready(function() {
      if (window.File && window.FileList && window.FileReader) {
       
        $("#up_files").on("change", function(e) {     
          var files = e.target.files
          var fileNum = files.length,
            initial = 0,          
            counter = 0;         
  
          $.each(files, function(idx, elm) {          
            self.upFileOBJ.push(elm);
          });       
      console.log(self.upFileOBJ);
          for (initial; initial < fileNum; initial++) {
            counter = counter + 1;
            if(self.checkDuplicatename(files[initial].name))
                  $('<div id="file_'+files[initial].name+'" class="file-preview" ><span class="fa-stack fa-lg"><i class="fa fa-file fa-stack-1x "></i><strong class="fa-stack-1x" style="color:#FFF; font-size:12px; margin-top:2px;"> </strong></span> ' + files[initial].name + '&nbsp;&nbsp;<span class="fa fa-times-circle fa-lg closeBtn" id="'+files[initial].name+'" title="remove"></span></div>').insertAfter(".file-drop-area");
          }         
          self.upFileOBJ=self.removeDuplicates(self.upFileOBJ,'name');
          console.log(self.upFileOBJ);
          $('.closeBtn').one('click',function(e){           
            e.preventDefault();
            var id = $(this).attr('id');        
            for( var i = 0; i < self.upFileOBJ.length; i++){ 
                                   
              if ( self.upFileOBJ[i].name === id) {              
                self.upFileOBJ.splice(i, 1); 
                 $(this).parent().remove();
                break;              
              }
          }
           console.log(self.upFileOBJ);
        });
        }); 
        
      } else {
        alert("Your browser doesn't support to File API")
      }
    });


    
    //const content = editor.getContent();
    this.admin_id = localStorage.getItem('admin_id');
    this.user_id = localStorage.getItem('userId');
    this.access_token = localStorage.getItem('access_token');
    this.profile_pic = localStorage.getItem('profile_image');
    this.user_type = localStorage.getItem('user_type');
    this.user_name = localStorage.getItem('agent_name');
    this.admin_permission = localStorage.getItem('admin_permision');
    this.has_robin = localStorage.getItem('round_robin');
    this.signature_strategy=localStorage.getItem('signature_strategy');
    this.switch_signature=localStorage.getItem('switch_signature');
    this.thread_order=localStorage.getItem('thread_order');
    // alert(this.signature_strategy)
    this.tick_id_ency = this.ticket_t;
    this.getTicketDetails(this.ticket_t);
   
    this.EmailToAddress = [];
    this.collobrators = [];
    // this.dept_settings();
     this.initTiny();
    this.get_singnature();
    this.getDeptAliasName();
    this.getAllCustomer();
    this.ticketProperty();
    if ($("body").hasClass("sidebar-mini")) {
      $("body").removeClass("sidebar-mini");
      $("body").addClass("sidebar-mini");
    } else {
      $("body").addClass("sidebar-mini");
    }

  });


  }


  ngAfterViewInit(){
    setTimeout(() => {
      this.selectedCountry = this.serverService.wholeCountry;
      console.log(this.selectedCountry);
    }, 1000);
  }

  private validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject  = {};
 
    for(var i in originalArray) {
     lookupObject[originalArray[i][prop]] = originalArray[i];
    }
 
    for(i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
     return newArray;
  }
 
  checkDuplicatename(name) {
   var val=true;
   var count=0;
    for( var i = 0; i < this.upFileOBJ.length; i++){ 
                                   
      if ( this.upFileOBJ[i].name === name) { 
                    
        count++;
      }
      if(count >=2){                
//return false if duplicates present
        val=false;
    
        break;
      }
  } 
 
  
     return val;
  }
  get_singnature() {
    let access_token: any = localStorage.getItem('access_token');

    let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"viewTicketSignature","admin_id":"' + this.admin_id + '","user_id":"' + this.user_id + '"}}';

    this.serverService.sendServer(api_req).subscribe((response: any) => {
      if (response.status == true) {

        this.singnatures = response.result.data;

        console.log(this.singnatures);
      }
    },
      (error) => {
        console.log(error);
      });
  }
 
  initTiny() {
    var richTextArea_id = 'richTextAreaReply';
   tinymce.init({
     selector: '#richTextAreaReply',
     height: 500,
     plugins: 'advlist autolink formatpainter lists link  image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste  wordcount autolink lists media table',
     toolbar: 'undo redo |fullscreen|forecolor backcolor| formatselect | bold italic | \ undo redo | link image file| code | \
       alignleft aligncenter alignright alignjustify | \
       bullist numlist outdent indent | autoresize',
     paste_data_images: true,
     images_upload_url: 'upload.php',
     automatic_uploads: false,
     default_link_target:"_blank",
     extended_valid_elements : "a[href|target=_blank]",
     link_assume_external_targets: true,
    //  link_context_toolbar: true,
     images_upload_handler: function (blobInfo, success, failure) {
       var xhr, formData;

       xhr = new XMLHttpRequest();
       xhr.withCredentials = false;
       xhr.open('POST', 'upload.php');

       xhr.onload = function () {
         var json;

         if (xhr.status != 200) {
           failure('HTTP Error: ' + xhr.status);
           return;
         }

         json = JSON.parse(xhr.responseText);

         if (!json || typeof json.file_path != 'string') {
           failure('Invalid JSON: ' + xhr.responseText);
           return;
         }

         success(json.file_path);
       };

       formData = new FormData();
       formData.append('file', blobInfo.blob(), blobInfo.filename());

       xhr.send(formData);
     },
   });
   if (tinymce.editors.length > 0) {
     //tinymce.execCommand('mceFocus', true, richTextArea_id );       
     tinymce.execCommand('mceRemoveEditor',true, richTextArea_id);        
     tinymce.execCommand('mceAddEditor',true, richTextArea_id);
 }
 }


  backtoPage() {

    // if(this.ticket_delete_status==true&&(this.user_type=='Admin'||this.admin_permission==1)){
    //           this.router.navigate(['/spam-list']);
    // }
    //  else if (this.unmarkspamshow == true && (this.user_type=='Admin'||this.admin_permission==1)) {
    //   this.router.navigate(['/spam-list']);
    // } else
    // {   
    // above code will take to spam/delete tickets for admins 
    if(this.page_type == 'salessupportReport'){
      this.router.navigate(['/sales-support-report']);
      return false;
    }
    
    if(this.page_type == 'viewfromreport'){
      this.router.navigate(['/enquiry-report']);
      return false;
    }
    
      if((this.ticket_agent == '' || this.ticket_agent == 'null'|| this.ticket_agent == null) &&(this.user_type=='Admin'||this.admin_permission==1||this.has_robin!=1)){
      this.router.navigate(['/unassinged-tickets']);
      }else{           
        this.router.navigate(['/ticketing-system-new']);   
        }
    // }

    // this._location.back();

  }

  getTicketDetails(tick_id) {
    this.EmailToAddress = [];
    this.collobrators = [];
    Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
		showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent'
		});

    this.offset_count = 0;
    if(this.thread_order=='1'){
      // alert('in')
    let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"change_thread_order","ticket_id":"' + tick_id + '","admin_id":"' + this.admin_id + '","limit":"' + this.pageLimit + '","offset":"' + this.offset_count + '"}}';
    this.serverService.sendServer(api_req).subscribe((response: any) => {
      Swal.close();
      if (response.status == "true") {
        // this.ticket_replies = response.tick_options.reverse(); -- Last hide
        this.ticket_replies = response.tick_options;
        this.customer_role = response.tick_options[0].customer_role;
        this.ticket_replies_all = response.tick_options;
        this.tick_subject = response.tick_options[0].subject;
        this.ticket_created_by = response.tick_options[0].ticket_created_by;
        this.tick_from = response.tick_options[0].user_name;
        this.ticket_status_sel = response.tick_options[0].ticket_status_id;
        this.agent_options_list=response.agents;
        this.country = response.tic_country_id;
        this.priority = response.tic_priority;
        this.requestType = response.tic_typeid;
        this.customer_name=response.tick_options[0].customer_name;
        this.customer_id=response.tick_options[0].customer_id;

        this.ticket_type=response.tick_options[0].ticket_type;
        //this.priorityList=response.tic_priority;
         //this.priorityList = response.priority;

        // CONVERT TICKET TO ENQUIRY CONDITION START
        let convertCondition = response.tick_options[0].ticket_enquiry_value;
        if(convertCondition == '0'){
          this.show_convert = true;
        }else if(convertCondition == '1'){
          this.show_convert = false;
        }
        // CONVERT TICKET TO ENQUIRY CONDITION END

        this.selectedEnquiryType = response.tick_options[0].ticket_enquiry_dropdown_id;
        if(this.selectedEnquiryType == '2'){
          this.revisit_show = true;
          this.revisit = this.DatePipe.transform(response.tick_options[0].ticket_revisit_date, 'yyyy-MM-dd');
        }else{
          this.revisit_show = false;
        }


        this.outcomeSelectValue = response.tick_options[0].ticket_enquiry_dropdown_id;
        if(this.outcomeSelectValue == '2'){
          this.revisitShowClose = true;
          this.revisit = this.DatePipe.transform(response.tick_options[0].ticket_revisit_date, 'yyyy-MM-dd');
        }else{
          this.revisitShowClose = false;
        }
       
        this.commentContent = response.tick_options[0].enquiry_outcome_comments;
        this.commentcloseContent = response.tick_options[0].enquiry_outcome_comments;

        console.log(this.revisit)
        if(this.ticket_type == "enquiry"){
          this.getEnquiryDropdown();
        }


        if (response.tick_options[0].is_spam == '0') {
          this.unmarkspamshow = false;
          this.markspamshow = true;
        } else if (response.tick_options[0].is_spam == '1') {
          this.unmarkspamshow = true;
          this.markspamshow = false;

        }
        this.ticket_delete_status=response.tick_options[0].ticket_delete_status == '1'?true:false;
        //   if(this.ticket_status_sel !=''){      
        //   this.get_agents_by_department_dup(this.ticket_status_sel)
        // }

        this.ticket_status = response.tick_options[0].ticket_status;
        
        if (this.ticket_status == "Closed") {
          this.closed = true;
        } else {
          this.closed = false;
          if (response.closed_at == "" || response.closed_at == null) {

          } else {
            this.Reopen_closed = true;
          }
        }
        this.first_letter = response.tick_options[0].first_letter;
        this.department = response.tick_options[0].department;

        this.selectedDepart = response.tick_options[0].depart_id;
        this.selectedDepart_perm= response.tick_options[0].depart_id;
        if (this.selectedDepart != '') {
          this.get_agents_by_department_dup(this.selectedDepart)
        }

        this.GetAgentbyDepartment(this.selectedDepart_perm)
        this.ticket_agent = response.tick_options[0].ticket_assigned_to;
        // setTimeout(() => {
        this.selectedAgentID = response.tick_options[0].ticket_assigned_to_id;
        // }, 4000);

        if(this.ticket_agent == '' || this.ticket_agent == 'null'|| this.ticket_agent == null) {
          this.CanClose=false;
        }else
        this.CanClose=true;

        this.tick_time = response.tick_options[0].issue_time;
        this.customer_role = response.tick_options[0].customer_role;
        this.ticket_to = response.tick_options[0].ticket_to;
        this.ticket_cc = response.tick_options[0].mail_cc;
        this.own_mail = response.tick_options[0].own_mail;
        this.priority_name = response.tick_options[0].priority;
        this.replied_from = response.tick_options[0].replied_from;
        this.mainCont = response.tick_options[0].ticket_message;
        this.ticket_message_id = response.tick_options[0].ticket_message_id;
        this.ticket_created_date = response.ticket_created_date;
        console.log('this.ticket_created_date', this.ticket_created_date);
        this.ticket_t = atob(this.ticket_t);
        this.status = response.status_options.filter(t => t.status_id != '9');
        // console.log(this.status);
        this.dept_list = response.departments;
        this.total_offet = response.total;
        this.reply_from_arr = response.tick_options[0].replied_from.split(',');
        // this.ticket_to_arr = response.tick_options[0].ticket_to.split(',');
        this.ticket_to_arr = response.tick_options[0].ticket_to.split(',');
        // var ticket_to_arr = response.tick_options[0].ticket_to.split(',');

        // if (this.ticket_to == response.tick_options[0].own_mail) {       
        //   if (response.tick_options[0].replied_from != 'null' && response.tick_options[0].replied_from != '')
        //     for (var value in this.reply_from_arr) {
        //       this.EmailToAddress.push({ email_to: this.reply_from_arr[value] });
        //     }

        // } else {    
        //   if (response.tick_options[0].ticket_to != 'null' && response.tick_options[0].ticket_to != '')
        //     for (var value in this.ticket_to_arr) {
        //       this.EmailToAddress.push({ email_to: this.ticket_to_arr[value] });
        //     }
        // }     
        // if (response.tick_options[0].mail_cc != 'null' && response.tick_options[0].mail_cc != '') {         
        //   var ticket_cc = this.ticket_cc.split(',')
        //   for (var value in ticket_cc) {
        //     this.collobrators.push({ email_name: ticket_cc[value] });
        //   }
        // }


      //  
       if (response.ticket_tocc_options.all_replied_to != 'null' && response.ticket_tocc_options.all_replied_to != ''){
        var replied_to=response.ticket_tocc_options.all_replied_to.split(',')
       for (var value in replied_to) {
              this.EmailToAddress.push({ email_to: replied_to[value] });
            }
        }
      // 
    //   if (response.ticket_tocc_options.all_replied_cc != 'null' && response.ticket_tocc_options.all_replied_cc != ''){
    //     var replied_cc=response.ticket_tocc_options.all_replied_cc.split(',');

    //     if(response.tick_options[0].replied_by == "Customer"){
 
    //       console.log(replied_cc);
    //       var replied_from_filter=response.tick_options[0].replied_from.split(',');
    //       replied_cc = replied_cc.filter(val => !replied_from_filter.includes(val));
    //       for (var value in replied_cc) {
    //         this.collobrators.push({ email_name: replied_cc[value] });
    //       }

    //       console.log(replied_cc);
    //     }else{ 
    //     for (var value in replied_cc) {
    //           this.collobrators.push({ email_name: replied_cc[value] });
    //         }
    //   }

    // }

        $('#dept_status').val(this.department);
        if ($('#dept_status').val() != null)
          this.get_agents_by_department(); 
           if (response.total > 5) {
          
          this.showmore_button = true;

        }


        this.first_res_time = response.first_res_time;
        this.closed_time = response.closed_at;
        this.closed_by = response.ticket_closed_by;
        // $("html, body").animate({ scrollBottom: 0 }, "slow");
        // window.scrollTo(0,document.body.scrollHeight);

        this.getParticipant();//Get Shared Agents
        this.ticket_created_date= response.ticket_created_date;
        setTimeout(() => {
        this.scroltomessage();
        }, 2000);

      }
    },
      (error) => {
        console.log(error);
      });
    }else{
      let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"viewExternalTicket","ticket_id":"' + tick_id + '","admin_id":"' + this.admin_id + '","limit":"' + this.pageLimit + '","offset":"' + this.offset_count + '"}}';
    this.serverService.sendServer(api_req).subscribe((response: any) => {
      Swal.close();
      if (response.status == "true") {
        this.ticket_replies = response.tick_options;
        this.ticket_replies_all = response.tick_options;
        this.customer_role = response.tick_options[0].customer_role;
        this.tick_subject = response.tick_options[0].subject;
        this.ticket_created_by = response.tick_options[0].ticket_created_by;
        this.tick_from = response.tick_options[0].user_name;
        this.ticket_status_sel = response.tick_options[0].ticket_status_id;
        this.country = response.tic_country_id;
        this.priority = response.tic_priority;
        this.requestType = response.tic_typeid;
        this.agent_options_list=response.agents;
        this.customer_name=response.tick_options[0].customer_name;
        this.customer_id=response.tick_options[0].customer_id;
        this.ticket_type=response.tick_options[0].ticket_type;
        this.ticket_created_date= response.ticket_created_date;
        console.log('this.ticket_created_date', this.ticket_created_date);
        //this.priorityList = response.priority;

// CONVERT TICKET TO ENQUIRY CONDITION START
        let convertCondition = response.tick_options[0].ticket_enquiry_value;
        if(convertCondition == '0'){
          this.show_convert = true;
        }else if(convertCondition == '1'){
          this.show_convert = false;
        }
// CONVERT TICKET TO ENQUIRY CONDITION END

        this.selectedEnquiryType = response.tick_options[0].ticket_enquiry_dropdown_id;
        if(this.selectedEnquiryType == '2'){
          this.revisit_show = true;
          this.revisit = this.DatePipe.transform(response.tick_options[0].ticket_revisit_date, 'yyyy-MM-dd');
        }else{
          this.revisit_show = false;
        }

        this.outcomeSelectValue = response.tick_options[0].ticket_enquiry_dropdown_id;
        if(this.outcomeSelectValue == '2'){
          this.revisitShowClose = true;
        }else{
          this.revisitShowClose = false;
        }


        console.log(this.revisit)
        if(this.ticket_type == "enquiry"){
          this.getEnquiryDropdown();
        }

        if (response.tick_options[0].is_spam == '0') {
          this.unmarkspamshow = false;
          this.markspamshow = true;
        } else if (response.tick_options[0].is_spam == '1') {
          this.unmarkspamshow = true;
          this.markspamshow = false;

        }
        this.ticket_delete_status=response.tick_options[0].ticket_delete_status == '1'?true:false;
        //   if(this.ticket_status_sel !=''){      
        //   this.get_agents_by_department_dup(this.ticket_status_sel)
        // }

        this.ticket_status = response.tick_options[0].ticket_status;
        if (this.ticket_status == "Closed") {
          this.closed = true;
        } else {
          this.closed = false;
          if (response.closed_at == "" || response.closed_at == null) {

          } else {
            this.Reopen_closed = true;
          }
        }
        this.first_letter = response.tick_options[0].first_letter;
        this.department = response.tick_options[0].department;

        this.selectedDepart = response.tick_options[0].depart_id;
        this.selectedDepart_perm= response.tick_options[0].depart_id;
        if (this.selectedDepart != '') {
          this.get_agents_by_department_dup(this.selectedDepart)
        }
        this.GetAgentbyDepartment(this.selectedDepart_perm);

        this.ticket_agent = response.tick_options[0].ticket_assigned_to;

        // setTimeout(() => {
        this.selectedAgentID = response.tick_options[0].ticket_assigned_to_id;
        // }, 4000);

        if(this.ticket_agent == '' || this.ticket_agent == 'null'|| this.ticket_agent == null) {
          this.CanClose=false;
        }else
        this.CanClose=true;
    
        this.tick_time = response.tick_options[0].ticket_created_at;
        this.ticket_to = response.tick_options[0].ticket_to;
        this.customer_role = response.tick_options[0].customer_role;
        this.ticket_cc = response.tick_options[0].mail_cc;
        this.own_mail = response.tick_options[0].own_mail;
        this.priority_name = response.tick_options[0].priority;
        this.replied_from = response.tick_options[0].replied_from;
        this.mainCont = response.tick_options[0].ticket_message;
        this.ticket_message_id = response.tick_options[0].ticket_message_id;
        this.ticket_t = atob(this.ticket_t);
        this.status = response.status_options.filter(t => t.status_id != '9');
        // console.log(this.status);
      
        this.dept_list = response.departments;
        // this.ticket_agent_country = response.result.data[0].country_name;
        // this.ticket_agent_priority = response.result.data[0].priority_name;
        // this.ticket_agent_requestType = response.result.data[0].type_name;
        this.total_offet = response.total;
        this.reply_from_arr = response.tick_options[0].replied_from.split(',');
        // this.ticket_to_arr = response.tick_options[0].ticket_to.split(',');
        this.ticket_to_arr = response.tick_options[0].ticket_to.split(',');
        // var ticket_to_arr = response.tick_options[0].ticket_to.split(',');

        // if (this.ticket_to == response.tick_options[0].own_mail) {       
        //   if (response.tick_options[0].replied_from != 'null' && response.tick_options[0].replied_from != '')
        //     for (var value in this.reply_from_arr) {
        //       this.EmailToAddress.push({ email_to: this.reply_from_arr[value] });
        //     }
        // } else {
        //   if (response.tick_options[0].ticket_to != 'null' && response.tick_options[0].ticket_to != '')
        //     for (var value in this.ticket_to_arr) {
        //       this.EmailToAddress.push({ email_to: this.ticket_to_arr[value] });
        //     }
        // }
        // if (response.tick_options[0].mail_cc != 'null' && response.tick_options[0].mail_cc != '') {
        //   var ticket_cc = this.ticket_cc.split(',')
        //   for (var value in ticket_cc) {
        //     this.collobrators.push({ email_name: ticket_cc[value] });
        //   }
        // }

   //  
   if (response.ticket_tocc_options.all_replied_to != 'null' &&response.ticket_tocc_options.all_replied_to != ''){
     
     var replied_to=response.ticket_tocc_options.all_replied_to.split(',')
   for (var value in replied_to) {
     
          this.EmailToAddress.push({ email_to: replied_to[value] });
        }
   }
  // 
  // if (response.ticket_tocc_options.all_replied_cc != 'null' && response.ticket_tocc_options.all_replied_cc != ''){  
  //   var replied_cc=response.ticket_tocc_options.all_replied_cc.split(',');
    

  //   if(response.tick_options[0].replied_by == "Customer"){
 
  //     console.log(replied_cc);
  //     var replied_from_filter=response.tick_options[0].replied_from.split(',');
  //     replied_cc = replied_cc.filter(val => !replied_from_filter.includes(val));
  //     for (var value in replied_cc) {
  //       this.collobrators.push({ email_name: replied_cc[value] });
  //     }

  //     console.log(replied_cc);
  //   }else{ 
  //   for (var value in replied_cc) {
  //         this.collobrators.push({ email_name: replied_cc[value] });
  //       }
  // }
  // // for (var value in replied_cc) {   
  // //        this.collobrators.push({ email_name: replied_cc[value] });
  // //      }
  //     }
        $('#dept_status').val(this.department);
        if ($('#dept_status').val() != null)
          this.get_agents_by_department();
        // $('#ticket_status select').val(response.tick_options[0].ticket_status_id);

        // $('#ticket_status [name=options] option').filter(function() { 
        //   return ($(this).text() == 'New'); //To select Blue
        // }).prop('selected', true);
        // $('#ticket_status [name="options"]').find('option[value="'+response.tick_options[0].ticket_status_id+'"]').attr("selected",true);
        // $('#ticket_status option[value="'+response.tick_options[0].ticket_status_id+'"]').attr('selected', 'selected').change();
        if (response.total > 5) {
          // alert(this.queue_list_all.length)
          this.showmore_button = true;

        }


        this.first_res_time = response.first_res_time;
        this.closed_time = response.closed_at;
        this.closed_by = response.ticket_closed_by;
        this.getParticipant();//Get Shared Agents

       this.ticket_created_date= response.ticket_created_date;
      }
    },
      (error) => {
        console.log(error);
      });
    }
  }

  //  toggleClass(){
  //   var x = document.getElementById("replyPanel");
  // if (x.style.display === "none") {
  //   x.style.display = "block";
  // } else {
  //   x.style.display = "none";
  // }
  // }


  toggleClass(id, to) {
    var x = document.getElementById("replyPanel_" + id);
    var y = document.getElementById("replyPanelall_" + id);
    y.style.display = "none";
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  toggleClassreplyall(id) {
    var x = document.getElementById("replyPanelall_" + id);
    // var y = document.getElementById("replyPanel_"+id);
    // y.style.display = "none";
    //  $('#'+y).hide();
    // alert(x);

    $('#replyPanelall_' + id).show();

    // if (x.style.display === "none") {
    //   x.style.display = "block";
    // } else {
    //   x.style.display = "none";
    // }
    // $("html, body").animate({ scrollTop: 3 }, "slow");

    document.getElementById("replyPanelall_" + id).scrollIntoView({
      behavior: 'smooth'
    });

  }
  clickDiscard(id) {

    $('#replyPanelall_' + id).hide();
    // let reply_message_{{this.ticket_t}}s
    //  tinymce.activeEditor.setContent('');
    //  tinymce.get('reply_message_'+ id+'s').setContent('');
     tinymce.get('richTextAreaReply').setContent('');
     $('#up_files').val('');
     $('.file-preview').remove(); 
     this.upFileOBJ=[];
  }

  testcloseticket(){    

    if(!this.CanClose){
    Swal.fire({
      title: "Close ticket Restricted",
      text: "Please claim this ticket to close",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Got it'
    });
    return false;
  }

    $('#close_thread_modal').modal('show');
  }

//   changeMyStatus() {
//     var tickto;
//     if (this.ticket_to == this.own_mail) {
//       tickto = this.replied_from;
//     } else {
//       tickto = this.ticket_to;
//     }
//     if(!this.CanClose){
//     Swal.fire({
//       title: "Close ticket Restricted",
//       text: "Please claim this ticket to close",
//       icon: 'warning',
//       showCancelButton: false,
//       confirmButtonColor: '#3085d6',
//       confirmButtonText: 'Got it'
//     });
//     return false;
//   }



//     let enquiry_input;
//     let options = "";
//       if (this.ticket_type == 'enquiry') {

//         this.closedTypeList.forEach(element => {
//           let optionTag = "<option value="+element.id+">"+element.name+"</option>";
//           options = options.concat(optionTag);
          
//         });
//         enquiry_input ="<select class='swal2-input edited' id='drpUserInput' onchange='test()'>" + options + "</select>";
//       }else{
//         enquiry_input = '';
//       }

//     Swal.fire({

//       html: enquiry_input,

//       title: 'Close the Ticket',
// 			input: 'select',
// 			inputOptions: {        
//         '1': 'Close - Notify Customer',
//         '0': 'Close - Notification Hidden'
//       },
//       inputValue: '1',
// 			confirmButtonText: 'Close',
// 			showCancelButton: true,

//     }).then((result) => {

//       var drpUserIdInput = $('#drpUserInput').val();
      
//       console.log(drpUserIdInput);


//       if (result.value) {
//         Swal.fire({
//           html:
//             '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
//         showCloseButton: false,
//           showCancelButton: false,
//           showConfirmButton: false,
//           focusConfirm: false,
//           background: 'transparent',
    
    
//         });
//   let api_req:any = new Object;
//   let post_req:any = new Object();
// api_req.operation="ticket";
// api_req.moduleType="ticket";
// api_req.api_type="web";
// api_req.access_token=localStorage.getItem('access_token');
//       post_req.admin_id = this.admin_id;
//       post_req.user_id = this.user_id;
//       post_req.action = "oncloseTocket";
//       post_req.status_id = "9";
//       post_req.alert_status = result.value;   
//       post_req.ticket_id = this.ticket_t;
//       post_req.ticket_to = tickto;
//       post_req.ticket_cc = this.ticket_cc;
//       post_req.agent_name = this.user_name;     
//       if (this.ticket_type == 'enquiry') {
//         post_req.enquiry_dropdown_id = drpUserIdInput;            
//       }   
// api_req.element_data=post_req;     


//         this.serverService.sendServer(api_req).subscribe((response: any) => {
//           Swal.close();
//           if (response.status == true) {
//             Swal.fire(
//               'Closed!',
//               'success'
//             );
//             this.backtoPage();
//             if(this.has_robin==1)	
//             this.check_robin_queue();

//           }
//         },
//           (error) => {
//             console.log(error);
//           });
//       }
//     })
//   }

  changeMyStatus() {


    var tickto;
    if (this.ticket_to == this.own_mail) {
      tickto = this.replied_from;
    } else {
      tickto = this.ticket_to;
    }

    if (this.ticket_type == 'enquiry') {
      console.log(this.outcomeSelectValue)
      if(this.outcomeSelectValue == null || this.outcomeSelectValue == '' || this.outcomeSelectValue == undefined || this.outcomeSelectValue == 0){
        this.showAlertData = true;
        return false;
      }else{
        this.showAlertData = false;
      }
      
    }


    $('#close_thread_modal').modal('hide');

  Swal.fire({
    html:
      '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
  showCloseButton: false,
    showCancelButton: false,
    showConfirmButton: false,
    focusConfirm: false,
    background: 'transparent',


  });


      let api_req:any = new Object;
      let post_req:any = new Object();
      api_req.operation="ticket";
      api_req.moduleType="ticket";
      api_req.api_type="web";
      api_req.access_token=localStorage.getItem('access_token');
      post_req.admin_id = this.admin_id;
      post_req.user_id = this.user_id;
      post_req.action = "oncloseTocket";
      post_req.status_id = "9";
      post_req.alert_status = this.closedNotification;   
      post_req.ticket_id = this.ticket_t;
      post_req.ticket_to = tickto;
      post_req.ticket_cc = this.ticket_cc;
      post_req.agent_name = this.user_name;     
      if (this.ticket_type == 'enquiry') {
        post_req.enquiry_dropdown_id = this.outcomeSelectValue;    
        if(this.outcomeSelectValue == '2'){
          post_req.revisit = this.CloseRevisit;   
        }else{
          post_req.enquiry_outcome_comments = this.commentcloseContent;
        }        
      }   
      api_req.element_data=post_req;     
      console.log(api_req)

        this.serverService.sendServer(api_req).subscribe((response: any) => {
          Swal.close();
          if (response.status == true) {
            Swal.fire(
              'Closed!',
              'success'
            );
            this.backtoPage();
            if(this.has_robin==1)	
            this.check_robin_queue();

          }
        },
          (error) => {
            console.log(error);
          });


  }


  changetoSpam() {

    let access_token: any = localStorage.getItem('access_token');
    let user_id: any = localStorage.getItem('userId');
 
    if(this.ticket_created_by==''||this.ticket_created_by==null||this.ticket_created_by=='null'){
      Swal.fire({
        title: 'OOPS',
        text: "Sorry, we could not find the email for spam. Please contact admin",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Spam'
      });
      return false;
    }
    Swal.fire({
      title: 'Are you sure?',
      text: "Would you like to Mark this Email as Spam!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Spam'
    }).then((result) => {
      if (result.value) {

        // {"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"", "element_data":{"action":"blockEmailIds","admin_id":"1203","user_id":"1253","email_id":"Cal4Care | MR < mr@cal4care.com >","spam_status":"0","blacklist_status":"1"}}
        Swal.fire({
          html:
            '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
        showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
          focusConfirm: false,
          background: 'transparent',
    
    
        });
        let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"blockEmailIds","admin_id":"' + this.admin_id + '","user_id":"' + user_id + '","email_id":"' + this.ticket_created_by + '","spam_status":"1","blacklist_status":"0" }}';
        this.serverService.sendServer(api_req).subscribe((response: any) => {
          Swal.close();
          if (response.result.status == true) {
            //this.router.navigate(['/ticketing-system-new']);
            iziToast.success({
              message: "Marked as Spam Successfully",
              position: 'topRight'
            });
            this.backtoPage();
            //this.router.navigate(['/ticketing-system-new']);
          } else {
            iziToast.error({
              message: "Failed to mark as spam",
              position: 'topRight'
            });
          }
        },
          (error) => {
            console.log(error);
          });


      }

    })

  }

  changetoUnspam() {

    let access_token: any = localStorage.getItem('access_token');
    let user_id: any = localStorage.getItem('userId');
    Swal.fire({
      title: 'Are you sure?',
      text: "Would you like to Mark as Not Spam!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Not Spam!'
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          html:
            '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
        showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
          focusConfirm: false,
          background: 'transparent'
        });
        // {"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"", "element_data":{"action":"blockEmailIds","admin_id":"1203","user_id":"1253","email_id":"Cal4Care | MR < mr@cal4care.com >","spam_status":"0","blacklist_status":"1"}}

        let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"blockEmailIds","admin_id":"' + this.admin_id + '","user_id":"' + user_id + '","email_id":"' + this.ticket_created_by + '","spam_status":"0","blacklist_status":"0" }}';
        this.serverService.sendServer(api_req).subscribe((response: any) => {
          Swal.close();
          if (response.result.status == true) {
            this.backtoPage();
            iziToast.success({
              message: "Unspam Successfully",
              position: 'topRight'
            });
          } else {
            iziToast.error({
              message: "Failed to unspam",
              position: 'topRight'
            });
          }
        },
          (error) => {
            console.log(error);
          });


      }

    })

  }



  // replyMesssage(msg_id,to){
  //   var msg_id = $('#message_'+msg_id).val();
  //   let api_req:any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"'+this.access_token+'", "element_data":{"action":"replyMessage","message":"'+msg_id+'","ticket_id":"'+this.ticket_t+'","to":"'+to+'"}}';
  //   this.serverService.sendServer(api_req).subscribe((response:any) => {
  //     if(response.status==true){
  //       Swal.fire(
  //         'Deleted!',
  //         'success'
  //       );
  //       this.getTicketDetails(this.ticket_t);
  //     } 
  //   }, 
  //   (error)=>{
  //     console.log(error);
  //   });

  // }

  getFields(input, field) {
    var output = [];
    for (var i = 0; i < input.length; ++i)
      output.push(input[i][field]);
    return output.toString();
  }


  replyMessage(msg_id, to, extra) {
    let access_token: any = localStorage.getItem('access_token');
    let user_id: any = localStorage.getItem('userId');
    let api_req: any = new Object();
    let agent_req: any = new Object();
   
    var formData = new FormData();
    var id = msg_id;
    // var fileName = $('#up_files_'+msg_id).val();
    // alert(fileName); return false;

    msg_id = msg_id + extra;

    // alert(msg_id);
    // if ((<HTMLInputElement>document.getElementById('up_files')).value != null) {
    //   var totalfiles = (<HTMLInputElement>document.getElementById('up_files')).files.length;
    //   for (var index = 0; index < totalfiles; index++) {
    //     formData.append("up_files[]", (<HTMLInputElement>document.getElementById('up_files')).files[index]);
    //   }
    // }
    if(this.upFileOBJ.length>0){
      for (var index = 0; index < this.upFileOBJ.length; index++) {
        formData.append("up_files[]",this.upFileOBJ[index]);
      }
    }

    // var to = $('#email_address_'+msg_id).val();
    // var cc = $('#mail_cc_'+msg_id).val();
    let sign = $('#singature_id').val();
    // alert(sign);
    var result_cc = this.getFields(this.collobrators, "email_name");
    var result_to = this.getFields(this.EmailToAddress, "email_to");



    //var msg_id = $('#editor').val();
    // var msg_id = $('#reply_message_'+msg_id).val();
    // tinymce.activeEditor.setContent('sdsdsd');
    // alert('1');

    // var msg_id = tinymce.get('reply_message_' + msg_id).getContent();
    var msg_id = tinymce.get('richTextAreaReply').getContent();
  
    if (msg_id == '' || msg_id == undefined) {
      iziToast.error({
        message: "Please Enter the Message",
        position: 'topRight'
      });
      return false;
    }
    if (result_to == '' || result_to == undefined) {
      iziToast.error({
        message: "Please Enter To Address ",
        position: 'topRight'
      });
      return false;
    }
    // alert(cc)
    // var upFileID = 'up_files_'+id+'s';
    // if((<HTMLInputElement>document.getElementById(upFileID)).value != null){
    //   var totalfiles = (<HTMLInputElement>document.getElementById(upFileID)).files.length;
    //   for (var index = 0; index < totalfiles; index++) {
    //     formData.append("up_files[]", (<HTMLInputElement>document.getElementById(upFileID)).files[index]);
    //   }
    // }
    

   var self=this;
    var promise = new Promise(function(resolve, reject) { 			
		
      if(self.customer_id=='null'||self.customer_id==null||self.customer_id==''){
        // var options = {};
        //   $.map(self.customer_details, function (o) {
        //     options[o.customerId] = o.customerName;
        //   });
        // const trans = Swal.fire({
        //   title: 'Assign Ticket to Customer',
        //   input: 'select',
        //   inputOptions: options,
        //   inputPlaceholder: 'Select Customer',
        //   confirmButtonText: 'Confirm',
        //   showCancelButton: true
        // }).then(function (inputValue) {
        //   if (inputValue.value != "" && inputValue.value != null) {      
        //       $('#customerID').val(inputValue.value);
        //       $('#customerID').click();
        //       resolve('');
        //   } else {            
        //   }
        // });
        $('#changecustomerpopup').modal('show');
        iziToast.warning({
          message:"Confirm the customer before sending the email",
          position:"topRight"
        });
        reject('');
      }else{
      // alert('el')
      
        resolve('');

      }
      
    
     }); 
    promise.then(() => { 
 // Retrict ALIAS Email Start
let myItems;
let result_to2=result_to.split(',');
for(let items of result_to2){
  console.log(items)
   myItems = this.Alias_emails.filter(item => item.toLowerCase() === items.toLowerCase());
  if(myItems.length>0){    
    Swal.fire({
      title: 'Email ID Restricted',
      html: "<p>You can't send mail to <b>"+items+"</b></p>",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Got it'
    })
    return false;
  
  }
}
let myItems2;
let result_cc2=result_cc.split(',');
for(let items of result_cc2){
  console.log(items)
  myItems2 = this.Alias_emails.filter(item => item.toLowerCase() === items.toLowerCase());
  if(myItems2.length>0){    
    Swal.fire({
      title: 'Email ID Restricted',
      html: "<p>You can't send mail to <b>"+items+"</b></p>",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Got it'
    })
    return false;
  
  }
}
 // Retrict ALIAS Email END

    formData.append('operation', 'ticket');
    formData.append('moduleType', 'ticket');
    formData.append('api_type', 'web');
    formData.append('access_token', access_token);
    formData.append('user_id', user_id);
    formData.append('action', 'replyTicketMessage');
    formData.append('message', msg_id);
    formData.append('ticket_id', this.ticket_t);
    formData.append('signature_id', sign);
    formData.append('to', result_to);
    formData.append('mail_cc', result_cc);
    formData.append('customer_id', this.customer_id);
    // formData.append('up_files', $('#reply_file')[0].files);
     // 20981110   ->this is 20 MB
    // 5242880   ->this is 5 MB
    // 10485760 =10MB 
    // var files = $('#up_files')[0].files;
    var totalSize = 0; 
    for (var i = 0; i < this.upFileOBJ.length; i++) {
      totalSize += this.upFileOBJ[i].size;
    }     
    if (totalSize < 10485760) {
    }
    else {
      iziToast.warning({
        message: "Sorry, File size should be below 10MB",
        position: 'topRight'
      });
     
      event.preventDefault();
      return false;
    }
    //alert(files[0].size);
    //  return false;
    //  alert(totalSize);
    //  return false;
    Swal.fire({
      title: 'Please Wait',
      allowEscapeKey: false,
      allowOutsideClick: false,
      //  background: '#19191a',
      showConfirmButton: false,
      onOpen: () => {
        Swal.showLoading();
      }
    });


    $.ajax({
      url: "https://ticketing.ringq.io/api/v1.0/index_new.php",
      type: 'POST',
      data: formData,
      processData: false,  // tell jQuery not to process the data
      contentType: false,
      success: function (data) {
        Swal.close();
        this.parsed_data = JSON.parse(data);
        console.log(this.parsed_data);
        if (this.parsed_data.status == 'true') {
          iziToast.success({
            message: "Replied Successfully",
            position: 'topRight'
          });


          var api_reqs:any = '{"type": "replyNotificationCount","ticket_num": "'+self.ticket_t+'"}';
          self.serverService.bellAlert.next(api_reqs);

          $("#refresh_page").click();
          $('#replyPanelall_' + id).hide();
          tinymce.get('richTextAreaReply').setContent('');
          $('#up_files').val('');
          self.upFileOBJ=[];
          $('.file-preview').remove(); 

          
        } else {
          iziToast.error({
            message: "Sorry, Some Error Occured",
            position: 'topRight'
          });
        }
      }
    });
  })
  }



  // changeMyStatusmain(ticket_id, status){
  //   let api_req:any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"'+this.access_token+'", "element_data":{"action":"onchange_status","status_id":"'+status+'","ticket_id":"'+ticket_id+'"}}';
  //   this.serverService.sendServer(api_req).subscribe((response:any) => {
  //     if(response.status==true){
  //     this.getTicketDetails(this.ticket_t);
  //     } 
  //   }, 
  //   (error)=>{
  //     console.log(error);
  //   });

  //   }
  refresh() {
    //alert(this.ticket_t);
    this.ticket_t = btoa(this.ticket_t);
    var t_id=this.ticket_t;
    this.getTicketDetails(t_id); 
    this.EmailToAddress = [];
    this.collobrators = [];
    if(this.has_robin==1){
      this.ticket_t = atob(this.ticket_t);
      this.check_robin_queue();
      this.ticket_t=btoa(this.ticket_t);
     }
     
  }
  get_agents_by_department() {
    var data = $('#dept_status').val();
    // alert(data)
    Swal.fire({
      html:
        '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      focusConfirm: false,
      background: 'transparent'
    });
    let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"get_agents_by_department","admin_id":"' + this.admin_id + '","dept_id":"' + data + '"}}';
    this.serverService.sendServer(api_req).subscribe((response: any) => {
      Swal.close();
      if (response.status == true) {
        this.agent_options = response.result.data;
        // this.getTicketDetails(this.ticket_t);
      }
    },
      (error) => {
        console.log(error);
      });
  }
  get_agents_by_department_dup(data) {
    // var data=$('#dept_status').val();
    // alert(data)
    Swal.fire({
      html:
        '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
    showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      focusConfirm: false,
      background: 'transparent'
    });
    let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"get_agents_by_department","admin_id":"' + this.admin_id + '","dept_id":"' + data + '"}}';
    this.serverService.sendServer(api_req).subscribe((response: any) => {
      Swal.close();
      if (response.status == true) {
        this.agent_options = response.result.data;
        // this.selectedAgentID=id;

        // this.getTicketDetails(this.ticket_t);
      }
    },
      (error) => {
        console.log(error);
      });
  }
  UpdateTicketStatus() {
   var sales_r = $('#sales_r').val();
   console.log('sales_r', sales_r);
    // if($('#sales_r').val() == 'Select Country' || sales_r == null ){
    //    iziToast.warning({
    //     message: "Please Select Country",
    //     position: "topRight"
    //   });
    //   return false;
    // }
    var prior_r = $('#prior_r').val();
    if($('#prior_r').val() == 'Select Priority' || prior_r == null ){
      iziToast.warning({
       message: "Please Select Priority",
       position: "topRight"
     });
     return false;
   }
   var requestType_r = $('#requestType_r').val();
//    if($('#requestType_r').val() == 'Select Support Request Type' || requestType_r == null ){
//     iziToast.warning({
//      message: "Please Select Country",
//      position: "topRight"
//    });
//    return false;
//  }
    

    // $('#replyPanelall_' + this.ticket_t).is(":hidden")

    if($('#replyPanelall_' + this.ticket_t).is(':visible')){
      //what you want to do when is visible
      console.log('visible');
      iziToast.warning({
        message: "Please Discard the Reply Section to Updated",
        position: "topRight"
      });
      return false;

    }else{
      console.log('hidden');
    }


    if(this.ticket_status_sel == '8'){
      $('#close_thread_modal').modal('show');
      return false;
    }else{
      $('#close_thread_modal').modal('hide');
    }



    // var dept = $('#dept_status').val();
    var dept = this.selectedDepart;
    var status = $('#ticket_status').val();
    var agent = $('#agent_options').val();
    var country = $('#sales_r').val();
    var prior = $('#prior_r').val();
    var requestType = $('#requestType_r').val();



    if (status == '') {
      status = this.ticket_status_sel;
    }
    if (dept == '') {
      iziToast.warning({
        message: "Please Select Department",
        position: "topRight"
      });
      return false;
    }

    
    // if(this.ticket_type == "enquiry"){
    //   this.selectedEnquiryType;
    //   if(this.selectedEnquiryType == '2'){
    //     this.revisit;
    //   }else{
    //     this.commentContent;
    //   }
    // }

    // if(agent == ''){
    //   iziToast.warning({
    //     message:"Please Select Agent",
    //     position:"topRight"
    //   });
    //   return false;
    // }
    Swal.fire({
      html:
        '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
    showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      focusConfirm: false,
      background: 'transparent',
    });

    //let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"updateTicketStatus","user_id":"' + this.user_id + '","ticket_id":"' + this.tick_id_ency + '","status":"' + status + '","department":"' + dept + '","agent_id":"' + agent + '","admin_id":"' + this.admin_id + '","user_name":"' + this.user_name + '"}}';

    let api_req: any = new Object();
    let chat_req: any = new Object();

    api_req.operation = "ticket";
    api_req.moduleType = "ticket";
    api_req.api_type = "web";
    chat_req.action = "updateTicketStatus";
    chat_req.access_token = this.access_token;
    chat_req.user_id = this.user_id;
    chat_req.ticket_id = this.tick_id_ency;
    chat_req.status = status;
    chat_req.department = dept;
    chat_req.typeid = requestType;
    chat_req.country_id = country;
    chat_req.priority = prior
    chat_req.agent_id = agent;
    chat_req.admin_id = this.admin_id;
    chat_req.user_name = this.user_name;
    if(this.ticket_type == "enquiry"){
      chat_req.enquiry_dropdown_id = this.selectedEnquiryType;
      if(this.selectedEnquiryType == '2'){
        chat_req.revisit = this.revisit;
      }else{
        chat_req.enquiry_outcome_comments = this.commentContent;
      }
    }
    api_req.element_data = chat_req;


    this.serverService.sendServer(api_req).subscribe((response: any) => {
      Swal.close();
      if (response.result.data == 1) {
        iziToast.success({
          message: "Ticket Updated Successfully ",
          position: "topRight"
        });
        // this.router.navigate(['/ticketing-system-new']);
this.backtoPage();
        // this.ticket_t=btoa(this.ticket_t);
        // this.getTicketDetails(this.ticket_t);
        // this.EmailToAddress=[];
        // this.collobrators=[];
        // if(this.selectedDepart_perm!=dept)
        //       this.checkRobinuser
             
if(this.has_robin==1 && this.selectedDepart_perm==dept && this.selectedAgentID==agent)	
   this.check_robin_queue();
         }
    },
      (error) => {
        console.log(error);
      });
  }
  AddNote() {
    let api_req: any = new Object();
    let chat_req: any = new Object();

    api_req.operation = "ticket";
    api_req.moduleType = "ticket";
    api_req.api_type = "web";
    chat_req.action = "addNotesForTicketReply";
    chat_req.admin_id = this.admin_id;
    chat_req.user_id = this.user_id;
    chat_req.ticket_id = this.ticket_t;
    chat_req.user_name = this.user_name;
    chat_req.ticket_message_id = this.ticket_message_id;
    chat_req.ticket_notes = $('#privateNote').val();
    api_req.element_data = chat_req;



    //  var note= $('#privateNote').val();
    // let api_req:any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"'+this.access_token+'", "element_data":{"action":"addNotesForTicketReply","admin_id":"'+this.admin_id+'","ticket_message_id":"'+this.ticket_t+'","ticket_notes":"'+note+'"}}';
    this.serverService.sendServer(api_req).subscribe((response: any) => {

      if (response.result.data == 1) {
        iziToast.success({
          message: "Private Note Added",
          position: "topRight"
        });
        $('#userDepartmentManagement').modal('hide');
        $('#privateNote').val('');
        this.ticket_t = btoa(this.ticket_t);
        this.getTicketDetails(this.ticket_t);
        
        this.EmailToAddress = [];
        this.collobrators = [];

      }
      else {
        iziToast.warning({
          message: "Sorry Some error ocurred",
          position: "topRight"
        });
      }
      $('#userDepartmentManagement').modal('hide');

    },
      (error) => {
        console.log(error);
        $('#userDepartmentManagement').modal('hide');

        iziToast.warning({
          message: "Sorry,Server issue ocurred,Please contact Admin",
          position: "topRight"
        });
      });
  }

  openModel() {
    $("#document_model").modal('show');

    $('#userDepartmentManagement').modal('show');
  }
  showmore() {
    // $("html, body").animate({ scrollTop: $(document).height() }, "slow");
    // $('.ticketing-system-panel').scrollTop($('.ticketing-system-panel')[0].scrollHeight);

    this.showmore_button = true;
    this.offset_count = this.offset_count + 5;   
    // this.offset_count = this.offset_count -this.total_offet;
    var offset = this.offset_count;
    if (this.total_offet > offset + 5) {
      if (this.offset_count >= this.total_offet) {
        this.offset_count = this.total_offet;
        this.showmore_button = false;
      }
    } else {
      this.showmore_button = false;
    }
    // alert(this.pageLimit)
    // alert(this.offset_count)
    if(this.thread_order=='1'){
      // if(this.thread_order=='0'){ --- last hide
    var api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"change_thread_order","ticket_id":"' + this.tick_id_ency + '","admin_id":"' + this.admin_id + '","limit":"' + this.pageLimit + '","offset":"' + this.offset_count + '"}}';
    this.serverService.sendServer(api_req).subscribe((response: any) => {
      if (response.status == "true") {
        // this.ticket_replies = response.tick_options;
        // this.queue_list = response.ticket_options;
        // this.queue_list_all = response.ticket_options;
        var mydatas = [];
        mydatas = response.tick_options;
        // alert(mydatas.length);		
        // this.queue_list = this.queue_list_all.push(mydatas); 
        for (let index = 0; index < mydatas.length; index++) {
          var data = mydatas[index];
          this.ticket_replies.push(data);
          // this.ticket_replies= [data].concat(this.ticket_replies);
        }

      }
    },
      (error) => {
        console.log(error);
      });
  }else{
     api_req= '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"viewExternalTicket","ticket_id":"' + this.tick_id_ency + '","admin_id":"' + this.admin_id + '","limit":"' + this.pageLimit + '","offset":"' + this.offset_count + '"}}';
     this.serverService.sendServer(api_req).subscribe((response: any) => {
      if (response.status == "true") {
        // this.ticket_replies = response.tick_options;
        // this.queue_list = response.ticket_options;
        // this.queue_list_all = response.ticket_options;
        var mydatas = [];
        mydatas = response.tick_options;
        // alert(mydatas.length);		
        // this.queue_list = this.queue_list_all.push(mydatas); 
        for (let index = 0; index < mydatas.length; index++) {
          var data = mydatas[index];
          this.ticket_replies.push(data);
        }

      }
    },
      (error) => {
        console.log(error);
      });
    }
   
  }
  getFileExtension(filename) {
    // console.log(filename);
    var ext = /^.+\.([^.]+)$/.exec(filename);
    this.ext = ext;
    return ext == null ? "" : ext[1];
  }
  // getFileName(file_name){
  //   var name_without_ext = (file_name.split('\\').pop().split('/').pop().split('.'))[0];
  //   console.log(name_without_ext);
  // return name_without_ext;
  // }
  getsignpopup() {
    let access_token: any = localStorage.getItem('access_token');
    let sign: any = $('#singature_id').val();
    let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type":"web", "access_token":"' + access_token + '", "element_data":{"action":"editTicketSignature","sig_id":"' + sign + '","admin_id":"' + this.admin_id + '"}}';
    this.serverService.sendServer(api_req).subscribe((response: any) => {
      if (response.result.status == true) {
        $('#showpop_signature').modal('show');
        this.signature_content = response.result.data[0].sig_content;
        this.signature_title = response.result.data[0].sig_title;
      } else {
        iziToast.warning({
          message: "Please try again",
          position: 'topRight'
        });
      }
    },
      (error) => {
        iziToast.error({
          message: "Sorry, some server issue occur. Please contact admin",
          position: 'topRight'
        });
        console.log(error);
      });
  }
  PickUpTicket() {
    Swal.fire({
      title: 'Claim this Ticket',
      text: 'Are you sure to PickUp this Ticket ##' + this.ticket_t + '',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        let tick_id = btoa(this.ticket_t);
        let access_token: any = localStorage.getItem('access_token');
        let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type":"web", "access_token":"' + access_token + '", "element_data":{"action":"claimMyTicket","user_id":"' + this.user_id + '","ticket_id":"' + tick_id + '","user_name":"' + this.user_name + '","admin_id":"' + this.admin_id + '"}}';
        this.serverService.sendServer(api_req).subscribe((response: any) => {
          if (response.result.status == true) {
            iziToast.success({
              message: "You have Claimed this ticket",
              position: 'topRight'
            });
            this.ticket_t = btoa(this.ticket_t);
            this.getTicketDetails(this.ticket_t);
          } else {
            iziToast.warning({
              message: "Please try again",
              position: 'topRight'
            });
          }
        },
          (error) => {
            iziToast.error({
              message: "Sorry, some server issue occur. Please contact admin",
              position: 'topRight'
            });
            console.log(error);
          });
      }
    });

  }

  DeleteTicket(){
	  // this.ticket_t = btoa(this.ticket_t);
    //alert(this.ticket_t);
		Swal.fire({
		  title: 'Are you sure?',
		  text: "You won't be able to revert this!",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
      
		  if (result.value) {
        Swal.fire({
          html:
            '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
        showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
          focusConfirm: false,
          background: 'transparent',
    
    
        });
		  let access_token: any=localStorage.getItem('access_token');
		
		//   let api_req:any = '{"operation":"predective_dialer_contact", "moduleType":"predective_dialer_contact", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"delete_contact","user_id":"'+this.user_id+'","contact_id":"'+invalidContacts+'"}}';
		  let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"delete_multiple_ticket","value":"' + this.ticket_t + '","admin_id":"' + this.admin_id + '"}}';
		
		  this.serverService.sendServer(api_req).subscribe((response:any) => {
	  Swal.close();
			console.log(response);
			if(response.result.data==true){
			  iziToast.success({
				message: "Ticket deleted successfully",
				position: 'topRight'
			});
      // this.router.navigate(['/ticketing-system-new']);
       this.backtoPage();
       if(this.has_robin==1)	
	         this.check_robin_queue();
     
			} else {
			  iziToast.warning({
				message: "Contact not deleted, Please try again!",
				position: 'topRight'
			});
			}
		  }, 
		  (error)=>{
			  console.log(error);
		  });
		
	  }  
	  })
	  }
    
    RestoreTicket(){
	  // this.ticket_t = btoa(this.ticket_t);
    //alert(this.ticket_t);
		Swal.fire({
		  title: 'Are you sure?',
		  text: "You want to restore this ticket",
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Yes!'
		}).then((result) => {
		  if (result.value) {
		  let access_token: any=localStorage.getItem('access_token');
      Swal.fire({
        html:
          '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
      showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
        background: 'transparent',
  
  
      });
		//   let api_req:any = '{"operation":"predective_dialer_contact", "moduleType":"predective_dialer_contact", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"delete_contact","user_id":"'+this.user_id+'","contact_id":"'+invalidContacts+'"}}';
		  let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"restore_ticket","ticket_id":"' + this.ticket_t + '","admin_id":"' + this.admin_id + '"}}';
		
		  this.serverService.sendServer(api_req).subscribe((response:any) => {
	  
Swal.close();
			if(response.result.data==true){
			  iziToast.success({
				message: "Ticket Restored successfully",
				position: 'topRight'
			});
      this.backtoPage();
      if(this.has_robin==1)	
	         this.check_robin_queue();
     
			} else {
			  iziToast.warning({
				message: "Contact not deleted, Please try again!",
				position: 'topRight'
			});
			}
		  }, 
		  (error)=>{
			  console.log(error);
		  });
		
	  }  
	  })
	  }

    add2(event: MatChipInputEvent): void {
      // console.log(this.addDept.status);
      const input = event.input;
      const value = event.value;
  
  
      if (this.validateEmail(event.value)) {
        // if(this.own_mail== input){
          let myItems = this.Alias_emails.filter(item => item.toLowerCase() === value.toLowerCase());
          if(myItems.length>0){
            this.show_email_errors = true;
            this.email_error_msg = 'Email ID not allowed';
           
        }else{         
            this.show_email_errors = false;
            this.email_error_msg='';
            if ((value || '').trim()) {
              this.addSenderThread.push({ name: value.trim() });
              this.emailFormControl = new FormControl({value: '', disabled: this.disabled});              
            }
            if (input) {
              input.value = '';
            }
  
          }
  
  
     
  
      } else {
        if(event.value!=""){
        this.show_email_errors = true;
        this.email_error_msg = 'Not an Valid EmailID';
        }
        else{        
          this.show_email_errors = false;
           this.email_error_msg = '';
        }
      }
    }
      
    remove2(code): void {
    const index = this.addSenderThread.indexOf(code);
    if (index >= 0) {
      this.addSenderThread.splice(index, 1);
    }
  }
  sendFullThread(){
    let access_token: any=localStorage.getItem('access_token');
    var new_array = [];
    this.addSenderThread.forEach(element => {
      new_array.push(element.name);
    });
    
    var forward_to = new_array.join(",");
    if(forward_to==""){
      iziToast.warning({
        message:"Enter valid Email",
        position:"topRight"
      })
      return false;
    }
    $('#send_thread_modal').modal('hide');
    this.emailFormControl = new FormControl({value: ''});  
    this.addSenderThread = [];
    Swal.fire({
			html:
				'<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
		showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false,
			focusConfirm: false,
			background: 'transparent',


		});
   let user_type = this.user_type == 'Admin' ? '2' : '3';
		//   let api_req:any = '{"operation":"predective_dialer_contact", "moduleType":"predective_dialer_contact", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"delete_contact","user_id":"'+this.user_id+'","contact_id":"'+invalidContacts+'"}}';
		  let api_req: any = '{"operation":"forward_ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"forward_ticket","ticket_id":"' + this.ticket_t + '","admin_id":"' + this.admin_id + '","user_id":"' + this.user_id + '","user_type":"' +user_type + '","forward_to":"' + forward_to + '","ticket_message_id":"' + this.ticket_message_id + '"}}';
		
		  this.serverService.sendServer(api_req).subscribe((response:any) => {
	  Swal.close();
			// console.log(response);
			if(response.status=='true'){
			  iziToast.success({
				message: "Ticket Forwarded successfully",
				position: 'topRight'
			});
      this.ticket_t = btoa(this.ticket_t);
      this.getTicketDetails(this.ticket_t);
			} else {
			  iziToast.warning({
				message: "Some,Server error occured",
				position: 'topRight'
			});
			}
		  }, 
		  (error)=>{
			  console.log(error);
		  });
  }
  clearEmailform(){
    this.emailFormControl = new FormControl({value: ''});  
    this.addSenderThread = [];
  }
  check_robin_queue(){
    let access_token: any=localStorage.getItem('access_token');
    let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"check_rounrobin","admin_id":"' + this.admin_id + '","user_id":"' + this.user_id + '","ticket_id":"' + this.ticket_t + '","dept":"' +this.selectedDepart_perm + '"}}';
		
    this.serverService.sendServer(api_req).subscribe((response:any) => {
 
    // console.log(response);
   
    }, 
    (error)=>{
      console.log(error);
    });
  }
  getShortName(fullName) { 
    fullName=fullName.trim();
    return fullName.substring(1, 0).toUpperCase();
  
  }
  getDeptAliasName(){
    // {"operation":"ticket","moduleType":"ticket","api_type":"web","access_token":"","element_data":{"action":"getMyAliasEmails","admin_id":"1203"}}
  
    let access_token: any = localStorage.getItem('access_token');
    let api_req: any = '{"operation":"ticket", "moduleType": "ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"getMyAliasEmails","admin_id":"' + this.admin_id + '"}}';
  
    this.serverService.sendServer(api_req).subscribe((response: any) => {
      if (response.status == true) {        // console.log(response);
        this.Alias_emails = response.result.data;
      }
    },
      (error) => {
        console.log(error);
      });
  
  }
  checkRobinuser(id,dept_id){
   
      let access_token: any=localStorage.getItem('access_token');
        let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"check_rounrobin_queue","admin_id":"' + this.admin_id + '","user_id":"' + id + '","dept_id":"' +dept_id + '"}}';
        this.serverService.sendServer(api_req).subscribe((response:any) => {
            
        }, 
        (error)=>{
          console.log(error);
        });
  
        }
        viewContract(id){
          Swal.fire({
            html:
              '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
          showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            focusConfirm: false,
            background: 'transparent'
          });
          let access_token: any=localStorage.getItem('access_token');
          let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"ticket_contract_details","cust_id":"' + id + '"}}';
            
          this.serverService.sendServer(api_req).subscribe((response:any) => { 
            Swal.close()
            if(response.result.data==null || response.result.data=='null' || response.result.data==''){
              Swal.fire({
                icon: 'info',
                title: 'No data',
                text: 'There is no contract details',
                });
            }
          else {
            this.contract_detial=response.result.data;
            $('#ContractDetails').modal('show');
            }
          }, 
          (error)=>{
            console.log(error);
          });
        }
        getAllCustomer(){
          // {"operation":"ticket","moduleType":"ticket","api_type":"web","access_token":"","element_data":{"action":"getMyAliasEmails","admin_id":"1203"}}
          //old Action name : getCustomerDetasils
          let access_token: any = localStorage.getItem('access_token');
          let api_req: any = '{"operation":"ticket", "moduleType": "ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"geterpCustomerDetasils","admin_id":"' + this.admin_id + '"}}';
        
          this.serverService.sendServer(api_req).subscribe((response: any) => {
            if (response.status == true) {        // console.log(response);
              this.customer_details = response.result.data;
              this.customer_details_all=response.result.data;
              this.start_list = 0;
              this.end_list = 20; 
              this.pagedropdown(this.start_list,this.end_list)
              this.showdatalist = true;
            }
          },
            (error) => {
              console.log(error);
            });
        
        }
        customerID(){
          this.customer_id=$('#customerID').val();
        }

      ChangeCustomer(id,email,name,code,country,phone){
          // {"operation":"ticket","moduleType":"ticket","api_type":"web","access_token":"","element_data":{"action":"getMyAliasEmails","admin_id":"1203"}}
        $('#changecustomerpopup').modal('hide');
          let text="Do you want to reassign this email Customer?";
         if(id=='0')
          text="Do you want to make this email as non-customer?";

          Swal.fire({
            title: 'Are you sure?',
            text: text,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
          }).then((result) => {
            if (result.value) {
          let access_token: any = localStorage.getItem('access_token');
          // let api_req: any = '{"operation":"ticket", "moduleType": "ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"updateCustomer","admin_id":"' + this.admin_id + '","ticket_id":"' +this.ticket_t + '","customer_id":"' + id + '"}}';
          let api_req: any = '{"operation":"ticket", "moduleType": "ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"updateCustomer","admin_id":"' + this.admin_id + '","ticket_id":"' +this.ticket_t + '","customer_id":"' + id + '","customer_email":"' + email + '","customer_name":"' + name + '","customer_code":"' + code + '","customer_country":"' + country + '","customer_phone":"' + phone + '"}}';
        
          this.serverService.sendServer(api_req).subscribe((response: any) => {
            if (response.status == true) {  
              iziToast.success({
                message:"Customer Detail updated Successfully",
                position:"topRight"
              })  
              // console.log(response);
              this.ticket_t = btoa(this.ticket_t);
              this.getTicketDetails(this.ticket_t);
            }
          },
            (error) => {
              console.log(error);
            });
          }

        })
        }
        searchChats(data) {
          // var search_txt = data.target.value.toLowerCase();
           //var search_txt = $('#chat_search').val();    
           this.showdatalist = true;

// console.log(data.length)
console.log(this.customer_details_all)
// return false
if(data.length>2){
        this.listsuggestion = this.customer_details_all.filter(
          book => book.customerName.toLowerCase().includes(data.toLowerCase()));
          if(this.listsuggestion=='' || this.listsuggestion==null)
              this.showdatalist = false;              
    }
//     setTimeout(() => {
// if(data.length<2){
//   iziToast.warning({
//     message:"Please enter aleast 3 charecters to search",
//     position:"topRight"
//   });
// }
//     }, 2000);
              if(data==''){
                this.start_list = 0;
                this.end_list = 20; 
                this.pagedropdown(this.start_list,this.end_list)
              }   
      }


      searchDatas(searchdata) {

        console.log(searchdata)
         if(searchdata.length>2){
        this.enquirySuggest = this.customer_details_all.filter(
                book => book.customerName.toLowerCase().includes(searchdata.toLowerCase()));
                     
          }

      }



      pagedropdown(start,end){       
        var i, j, chunk = 20;
        for (i = start, j = end; i < j; i += chunk) {
          this.listsuggestion = this.customer_details_all.slice(i, i + chunk);          
        }
      }
      
       nextPage(){
        this.start_list = this.start_list+20;
        this.end_list = this.end_list+20;        
        this.pagedropdown(this.start_list,this.end_list);
      }
      
      prevPage(){
        this.start_list = this.start_list-20;
        this.end_list = this.end_list-20;      
        this.pagedropdown(this.start_list,this.end_list);
      } 
      getTicketDetails_noti(tick_id) {
        this.EmailToAddress = [];
        this.collobrators = [];
      
    
        this.offset_count = 0;
        let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"viewExternalTicket","ticket_id":"' + tick_id + '","admin_id":"' + this.admin_id + '","limit":"' + this.pageLimit + '","offset":"' + this.offset_count + '"}}';
        this.serverService.sendServer(api_req).subscribe((response: any) => {
          Swal.close();
          if (response.status == "true") {
            this.ticket_replies = response.tick_options;
            this.ticket_replies_all = response.tick_options;
            this.tick_subject = response.tick_options[0].subject;
            this.ticket_created_by = response.tick_options[0].ticket_created_by;
            this.tick_from = response.tick_options[0].user_name;
            this.ticket_status_sel = response.tick_options[0].ticket_status_id;
    
            this.customer_name=response.tick_options[0].customer_name;
            this.customer_id=response.tick_options[0].customer_id;
            this.ticket_type=response.tick_options[0].ticket_type;

            this.priorityList = response.priority;

// CONVERT TICKET TO ENQUIRY CONDITION START
        let convertCondition = response.tick_options[0].ticket_enquiry_value;
        if(convertCondition == '0'){
          this.show_convert = true;
        }else if(convertCondition == '1'){
          this.show_convert = false;
        }
// CONVERT TICKET TO ENQUIRY CONDITION END

            this.selectedEnquiryType = response.tick_options[0].ticket_enquiry_dropdown_id;
            if(this.selectedEnquiryType == '2'){
              this.revisit_show = true;
            }else{
              this.revisit_show = false;
            }

            this.outcomeSelectValue = response.tick_options[0].ticket_enquiry_dropdown_id;
            if(this.outcomeSelectValue == '2'){
              this.revisitShowClose = true;
            }else{
              this.revisitShowClose = false;
            }

            this.revisit = response.tick_options[0].ticket_revisit_date;

              if(this.ticket_type == "enquiry"){
                this.getEnquiryDropdown();
              }

            if (response.tick_options[0].is_spam == '0') {
              this.unmarkspamshow = false;
              this.markspamshow = true;
            } else if (response.tick_options[0].is_spam == '1') {
              this.unmarkspamshow = true;
              this.markspamshow = false;
    
            }
            this.ticket_delete_status=response.tick_options[0].ticket_delete_status == '1'?true:false;
            //   if(this.ticket_status_sel !=''){      
            //   this.get_agents_by_department_dup(this.ticket_status_sel)
            // }
    
            this.ticket_status = response.tick_options[0].ticket_status;
            if (this.ticket_status == "Closed") {
              this.closed = true;
            } else {
              this.closed = false;
              if (response.closed_at == "" || response.closed_at == null) {
    
              } else {
                this.Reopen_closed = true;
              }
            }
            this.first_letter = response.tick_options[0].first_letter;
            this.department = response.tick_options[0].department;
    
            this.selectedDepart = response.tick_options[0].depart_id;
            this.selectedDepart_perm= response.tick_options[0].depart_id;
            if (this.selectedDepart != '') {
              this.get_agents_by_department_dup(this.selectedDepart)
            }
    
    
            this.ticket_agent = response.tick_options[0].ticket_assigned_to;
    
            // setTimeout(() => {
            this.selectedAgentID = response.tick_options[0].ticket_assigned_to_id;
            // }, 4000);
    
            if(this.ticket_agent == '' || this.ticket_agent == 'null'|| this.ticket_agent == null) {
              this.CanClose=false;
            }else
            this.CanClose=true;
    
            this.tick_time = response.tick_options[0].ticket_created_at;
            this.ticket_to = response.tick_options[0].ticket_to;
            this.ticket_cc = response.tick_options[0].mail_cc;
            this.own_mail = response.tick_options[0].own_mail;
            this.replied_from = response.tick_options[0].replied_from;
            this.mainCont = response.tick_options[0].ticket_message;
            this.ticket_message_id = response.tick_options[0].ticket_message_id;
            this.ticket_t = atob(this.ticket_t);
            this.status = response.status_options.filter(t => t.status_id != '9');
            // console.log(this.status);
            this.dept_list = response.departments;
            this.total_offet = response.total;
            this.reply_from_arr = response.tick_options[0].replied_from.split(',');
            // this.ticket_to_arr = response.tick_options[0].ticket_to.split(',');
            this.ticket_to_arr = response.tick_options[0].ticket_to.split(',');
            // var ticket_to_arr = response.tick_options[0].ticket_to.split(',');
    
            if (this.ticket_to == response.tick_options[0].own_mail) {
              // this.EmailToAddress.push({email_to:response.tick_options[0].replied_from.split(',')});
              if (response.tick_options[0].replied_from != 'null' && response.tick_options[0].replied_from != '')
                for (var value in this.reply_from_arr) {
                  this.EmailToAddress.push({ email_to: this.reply_from_arr[value] });
                }
    
            } else {
    
              // this.EmailToAddress.push({email_to:response.tick_options[0].ticket_to});
              if (response.tick_options[0].ticket_to != 'null' && response.tick_options[0].ticket_to != '')
                for (var value in this.ticket_to_arr) {
                  this.EmailToAddress.push({ email_to: this.ticket_to_arr[value] });
                }
            }
    
    
    
            console.log(this.EmailToAddress)
    
    
            if (response.tick_options[0].mail_cc != 'null' && response.tick_options[0].mail_cc != '') {
              // this.collobrators.push({email_name: response.tick_options[0].mail_cc.split(',')});
              var ticket_cc = this.ticket_cc.split(',')
              for (var value in ticket_cc) {
                this.collobrators.push({ email_name: ticket_cc[value] });
              }
            }
    
    
            // this.getdeptuser(this.department);dept_status
            $('#dept_status').val(this.department);
            if ($('#dept_status').val() != null)
              this.get_agents_by_department();
            // $('#ticket_status select').val(response.tick_options[0].ticket_status_id);
    
            // $('#ticket_status [name=options] option').filter(function() { 
            //   return ($(this).text() == 'New'); //To select Blue
            // }).prop('selected', true);
            // $('#ticket_status [name="options"]').find('option[value="'+response.tick_options[0].ticket_status_id+'"]').attr("selected",true);
            // $('#ticket_status option[value="'+response.tick_options[0].ticket_status_id+'"]').attr('selected', 'selected').change();
            if (response.total > 5) {
              // alert(this.queue_list_all.length)
              this.showmore_button = true;
    
            }
    
    
            this.first_res_time = response.first_res_time;
            this.closed_time = response.closed_at;
            this.closed_by = response.ticket_closed_by;
          }
        },
          (error) => {
            console.log(error);
          });
      }
    ThreadOrder(id){
      Swal.fire({
        html:
          '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
      showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
        background: 'transparent'
      });
      let access_token: any = localStorage.getItem('access_token');
          // let api_req: any = '{"operation":"ticket", "moduleType": "ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"updateCustomer","admin_id":"' + this.admin_id + '","ticket_id":"' +this.ticket_t + '","customer_id":"' + id + '"}}';
          let api_req: any = '{"operation":"ticket", "moduleType": "ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"update_thread_strategy","user_id":"' + this.user_id + '","value":"' +id + '"}}';
        
          this.serverService.sendServer(api_req).subscribe((response: any) => {
            if (response.status == true) {
            
            localStorage.setItem('thread_order',id);
            this.thread_order=localStorage.getItem('thread_order');
            // console.log(response);
              this.ticket_t = btoa(this.ticket_t);
              this.getTicketDetails(this.ticket_t);
            }
          },
            (error) => {
              console.log(error);
            });
          }
      scroltomessage(){
               let ms_id=this.ticket_replies[this.ticket_replies.length-1].ticket_message_id;
    

        // document.getElementById('scroll_message_'+ms_id).scrollIntoView({
        //   behavior: 'smooth'
        // }); -- last hide
        var myElement = document.getElementById('scroll_message_'+ms_id);
var topPos = myElement.offsetTop;
      }
      
      GetAgentbyDepartment(data) {
        // var data = $('#dept_status').val();
        // alert(data)
        Swal.fire({
          html:
            '<div style="display: flex;justify-content: center;"><div class="pong-loader"></div></div>',
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
          focusConfirm: false,
          background: 'transparent'
        });
        let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"get_agents_by_department","admin_id":"' + this.admin_id + '","dept_id":"' + data + '"}}';
        this.serverService.sendServer(api_req).subscribe((response: any) => {
          Swal.close();
          if (response.status == true) {
            this.agent_options_dept = response.result.data;
            // this.getTicketDetails(this.ticket_t);
          }
        },
          (error) => {
            console.log(error);
          });
      }
AddParticipant(){
//  var arr = this.agent_options_dept.filter(val => !this.shared_agents.includes(val));
var option_agents=this.agent_options_dept;
for (let [i, share] of this.shared_agents.entries()) {  
  for (let [j, user] of this.agent_options_dept.entries()) {    
  if (user.user_id == share.user_id) {     
      option_agents.splice(j, 1);
  }
}
} 
 
  var options = {};
  $.map(option_agents, function (o) {
    options[o.user_id] = o.agent_name;
  });
  options = Object.assign({ '-1': "Queue" }, options);
   //console.log(options);
  const trans = Swal.fire({
    title: 'Share the ticket',
    input: 'select',
    inputOptions: options,
    inputPlaceholder: 'Select Agent',
    confirmButtonText: 'Assign',
    showCancelButton: true
  
  }).then(function (inputValue) {
    if (inputValue.value != "" && inputValue.value != null) {

      $('#addParticpant_id').val(inputValue.value);
      $('#addParticpant_id').click();
    

    } else {
      // iziToast.error({
      //   message: "You have not selected any schedule",
      //   position: 'topRight'
      //   });
    }
  });
}
AddParticipantfunc(){
 var agent_id=$('#addParticpant_id').val();
 let access_token: any = localStorage.getItem('access_token');

 let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"share_external_ticket","admin_id":"' + this.admin_id + '","agent_id":"' + agent_id + '","user_id":"' +this.user_id+ '","ticket_id":"' + this.ticket_t + '"}}';
 this.serverService.sendServer(api_req).subscribe((response: any) => {
   Swal.close();
   iziToast.success({
     message:"Ticket have been shared",
     position:"topRight"
   });
   this.getParticipant();
   
 },
   (error) => {
     console.log(error);
   });
}
getParticipant(){
  var agent_id=$('#addParticpant_id').val();
  let access_token: any = localStorage.getItem('access_token');
 
  let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"shared_agent_list","admin_id":"' + this.admin_id + '","user_id":"' +this.user_id+ '","ticket_id":"' + this.ticket_t + '"}}';
  this.serverService.sendServer(api_req).subscribe((response: any) => {
    if(response.status=='true'){
      this.shared_agents = response.options;
      console.table(this.shared_agents.agent_name)
    }else{
      this.shared_agents =[];
    }
    console.log(this.shared_agents)
    // this.AddParticipant();
    
  },
    (error) => {
      console.log(error);
    });
 }


 pageChanged(event){
  this.config.currentPage = event;
}


addCustomer(){

  // {"operation":"add_custom_customer","moduleType":"ticket","api_type":"web","access_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJvbW5pdGlja2V0cy5tY29ubmVjdGFwcHMuY29tIiwiYXVkIjoib21uaXRpY2tldHMubWNvbm5lY3RhcHBzLmNvbSIsImlhdCI6MTY1MjUwNDM0NSwibmJmIjoxNjUyNTA0MzQ1LCJleHAiOjE2NTI1MjIzNDUsImFjY2Vzc19kYXRhIjp7InRva2VuX2FjY2Vzc0lkIjoiNjQiLCJ0b2tlbl9hY2Nlc3NOYW1lIjoiU2FsZXNBZG1pbiIsInRva2VuX2FjY2Vzc1R5cGUiOiIyIn19.fOrDjWantOkvqYbSMlbze3q31-HZlCIzm7Lu0kQ7Yt8","element_data":{"action":"add_custom_customer","admin_id":"64","customer_name":"NEWCUSTOMER","customer_email":"test@nb.com","phone_number":"54546546","country":"Singapore"}}

  
  console.log(this.addCustomerDetails);
  let access_token: any = localStorage.getItem('access_token');
  let api_req: any = '{"operation":"add_custom_customer", "moduleType":"ticket", "api_type": "web", "access_token":"' + this.access_token + '", "element_data":{"action":"add_custom_customer","admin_id":"' + this.admin_id + '","customer_name":"' +this.addCustomerDetails.value.customer_name_email+ '","customer_email":"' + this.addCustomerDetails.value.customer_ids_email + '","phone_number":"'+this.addCustomerDetails.value.customer_phone+'","country":"'+this.addCustomerDetails.value.customer_country+'"}}';
  this.serverService.sendServer(api_req).subscribe((response: any) => {
    if(response.result.data == '1'){
    this.getAllCustomer();

    iziToast.success({
      message: "Customer Add Successfully",
      position: 'topRight'
    });
    }
  },
    (error) => {
      console.log(error);
    });

}


searchFromEmails(searchText){

  // {"operation":"customer_search_function","moduleType":"ticket","api_type":"web","access_token":"","element_data":{"action":"customer_search_function","admin_id":"64","search_text":""}}

  let api_req:any = new Object();
  let post_req:any = new Object();
    api_req.operation="customer_search_function";
    api_req.moduleType="ticket";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');
    post_req.action = "customer_search_function";
    post_req.admin_id = this.admin_id;
    post_req.search_text = searchText;

    api_req.element_data=post_req;     


    this.serverService.sendServer(api_req).subscribe((response: any) => {

          if(response.status == "true"){
            // console.log(response)
            this.suggestEmailList = response.email;

          }
        },
        (error) => {
          console.log(error);
        });
    
}

updateCustomerEmail(updateText){


  let api_req:any = new Object();
  let post_req:any = new Object();
    api_req.operation="change_from_function";
    api_req.moduleType="ticket";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');
    post_req.action = "change_from_function";
    post_req.admin_id = this.admin_id;
    post_req.cusmail = updateText;
    post_req.ticket_no = this.ticket_t;

    api_req.element_data=post_req;     


    this.serverService.sendServer(api_req).subscribe((response: any) => {

          if(response.result.data == "1"){

            iziToast.success({
              message: "Customer From Email Updated Successful!",
              position: 'topRight'
            });
            
            $('#changefromEmail').modal('hide');
            this.getTicketDetails(btoa(this.ticket_t));
          }else{

            iziToast.error({
              message: "Customer From Email Updation Failed",
              position: 'topRight'
            });
          }
        },
        (error) => {
          console.log(error);
        });
    
}

getEnquiryDropdown(){
  // {"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"", "element_data":{"action":"list_enquiry_dropdown"}}

  let api_req:any = new Object();
  let dropdown_req:any = new Object();
    api_req.operation="ticket";
    api_req.moduleType="ticket";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');
    dropdown_req.action = "list_enquiry_dropdown";

    api_req.element_data=dropdown_req;     

    this.serverService.sendServer(api_req).subscribe((response: any) => {

      console.log(response);
      this.enquiryTypeList = response.data;
      this.outcome_list = response.data;
      this.closedTypeList = this.enquiryTypeList.filter(word => word.id != 2);

    },
    (error) => {
      console.log(error);
    });


}

getFilterData(ids){

  if(ids != undefined && ids != null && ids != 0) {

    if(this.enquiryTypeList != undefined && this.enquiryTypeList != null){
      let test = this.enquiryTypeList.filter(word => word.id == ids);
      return test[0].name;
    }else{
      return '';
    }
  }else{
    return 'Select OutCome'
  }
}
getFilterClosedData(ids){

  if(ids != undefined && ids != null && ids != 0) {

    if(this.outcome_list != undefined && this.outcome_list != null){
      let test = this.outcome_list.filter(word => word.id == ids);
      return test[0].name;
    }else{
      return '';
    }
  }else{
    return 'Select OutCome'
  }
}

changeMyFilter(datas){
  this.selectedEnquiryType = datas;
  if(this.selectedEnquiryType == '2'){
    this.revisit_show = true;
  }else{
    this.revisit_show = false;
  }
}

get_enquiryChanges(){
  if(this.selectedEnquiryType == '2'){
    this.revisit_show = true;
  }else{
    this.revisit_show = false;
  }
}

changeEnquiryState(data){
  this.outcomeSelectValue = data;
  if(this.outcomeSelectValue == '2'){
    this.revisitShowClose = true;
  }else{
    this.revisitShowClose = false;
  }

  
}

changeRemainderType(data){
  this.prorityFlagsSelect = data;
 
  
}

AddEnquiryDetails(){
  $('#convertionEnquiryModal').modal('hide');
  setTimeout(() => {
    
    Swal.fire({

    title: 'Are you sure?',
    text: "You cannot able to revert as Normal Ticket!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Convert it!',
    allowOutsideClick: false

    
        }).then((result) => {

    if (result.value) {


    let api_req:any = new Object();
    let convertion_req:any = new Object();
    api_req.operation="ticket";
    api_req.moduleType="ticket";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');
    convertion_req.action = "update_ticket_type";
    convertion_req.type = "enquiry";
    convertion_req.admin_id = this.admin_id;
    convertion_req.ticket_no = this.ticket_t;
    api_req.element_data=convertion_req;     

    this.serverService.sendServer(api_req).subscribe((response: any) => {

      console.log(response);

      $('#convertionEnquiryModal').hide();

      // {"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"", "element_data":{"action":"update_enquiry_details","admin_id":"64","ticket_no":"68664","enquiry_company":"testcompany","enquiry_country":"testcountry","enquiry_comments":"test comments","enquiry_dropdown_id":"1","revisit_date":""}}

      let api_req2:any = new Object();
      let addEnquiryNewreq:any = new Object();
      api_req2.operation="ticket";
      api_req2.moduleType="ticket";
      api_req2.api_type="web";
      api_req2.access_token=localStorage.getItem('access_token');
      addEnquiryNewreq.action = "update_enquiry_details";
      addEnquiryNewreq.type = "enquiry";
      addEnquiryNewreq.admin_id = this.admin_id;
      addEnquiryNewreq.enquiry_company = this.enquiryDetailsData.value.enquiryCmpy;
      addEnquiryNewreq.enquiry_country = this.enquiryDetailsData.value.enquiryCotry;
      addEnquiryNewreq.enquiry_comments = this.enquiryDetailsData.value.enquiryComments;
      addEnquiryNewreq.ticket_no = this.ticket_t;
      api_req2.element_data=addEnquiryNewreq;     
  
      this.serverService.sendServer(api_req2).subscribe((response: any) => {
  
        console.log(response);
        
        $('#convertionEnquiryModal').hide();

        iziToast.success({
          message: "Updated the Enquiry Details Successfully ",
          position: "topRight"
        });

        window.location.reload();
  
      },
      (error) => {
        console.log(error);
      });



    },
    (error) => {
      console.log(error);
    });
    
  }
    
  });
}, 1000);

}

converttoEnquiry(){

  // {"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"", "element_data":{"action":"update_ticket_type","type":"enquiry","admin_id":"64","ticket_no":"68664"}}

  $('#convertionEnquiryModal').modal('show');


}

teststatusfunction(){
  $('#convertionEnquiryModal').modal('show');
}

addRemainderDetails(){
  // {"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"", "element_data":{"action":"add_thread_notification","admin_id":"64","comments":"notification","ticket_no":"68775","notification_datetime":"2022-06-28 18:07:21","agent_id":"1311","flag":"1","customer_name":"test"}}

  let Filterdata = this.priorityList;

  let selectedFlagType  =  Filterdata.filter(word => word.priority == this.prorityFlagsSelect);
  let api_req:any = new Object();
  let notifyALert:any = new Object();
  api_req.operation="ticket";
  api_req.moduleType="ticket";
  api_req.api_type="web";
  api_req.access_token=localStorage.getItem('access_token');
  notifyALert.action = "add_thread_notification";
  notifyALert.type = "enquiry";
  notifyALert.admin_id = this.admin_id;
  notifyALert.ticket_no = this.ticket_t;
  notifyALert.comments = this.Remainder.value.remaindarNotes;
  notifyALert.notification_datetime = this.Remainder.value.remainderDate;
  notifyALert.agent_id = this.user_id;
  notifyALert.flag = selectedFlagType[0].id;
  notifyALert.customer_name = this.customer_name;
  api_req.element_data=notifyALert;     

  this.serverService.sendServer(api_req).subscribe((response: any) => {

    if(response.result.status == true){
      this.Remainder.patchValue({
        'remainderDate':null,
        'remaindarNotes':null
      });
      this.prorityFlagsSelect = 'Select Priority';

  $('#setRemanderModal').modal('hide');


  let api_reqs:any = '{"type": "notificationCount"}';
  this.serverService.bellAlert.next(api_reqs);

      iziToast.success({
        message: "Updated the Remainder Successfully ",
        position: "topRight"
      });


    }else{
      iziToast.success({
        message: "Failed to Updated the Remainder! ",
        position: "topRight"
      });
      
    }
    
  },
  (error) => {
    console.log(error);
  });
}


showStatus(){
  console.log(this.ticket_status_sel);
  if(this.ticket_status_sel == '8'){
    $('#close_thread_modal').modal('show');
  }else{
    $('#close_thread_modal').modal('hide');
  }
}


ticketProperty() {

  let data: any = new Object();
  let datasend: any = new Object();
  data.action = "cms_dropdown";
  datasend.access_token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJvbW5pdGlja2V0cy5tY29ubmVjdGFwcHMuY29tIiwiYXVkIjoib21uaXRpY2tldHMubWNvbm5lY3RhcHBzLmNvbSIsImlhdCI6MTY4NzMyNzQwMSwibmJmIjoxNjg3MzI3NDAxLCJleHAiOjE2ODczNDU0MDEsImFjY2Vzc19kYXRhIjp7InRva2VuX2FjY2Vzc0lkIjoiMTMxOSIsInRva2VuX2FjY2Vzc05hbWUiOiJDYWw0Q2FyZSBUSyIsInRva2VuX2FjY2Vzc1R5cGUiOiI0In19.QKGb0VTecEEwc431CKLQJoyG9NvoNui_iQfmE5LZfYk";
  datasend.operation = "ticket";
  datasend.moduleType = "ticket";
  datasend.api_type = "web";
  datasend.element_data = data;
  this.serverService.sendServer(datasend).subscribe((response: any) => {
    console.log(response)
    if (response.status == "true") {
      
      this.countryList=response.country;
      this.priorityList=response.priority;
      this.supportRequestTypeList=response.type;
     
    }
    else {

    }
  },
    (error) => {
      console.log(error);
    });

}

}
